import styled     from 'styled-components';
import { Primer } from '@scriptscouts/react-platform-client/src/components/common';
import { colors } from '@scriptscouts/react-platform-client/src/utility/colors';
import {
  spacing,
  breakpoints
} from '@scriptscouts/react-platform-client/src/utility/measurements';

const { Heading } = Primer;

export const StyledContainer = styled.div`
  color: ${colors['textSecondary']};
  display: flex;
  padding: 0 ${spacing['lg']};
  flex-direction: column;
  align-items: center;
  gap: ${spacing['2xl']};
  align-self: stretch;
  text-align: center;
  font-family: Manrope;
  position: relative;
  top: -104px;
`;

export const StyledDottedSeparatorImage = styled.img`
  rotate: 90deg;
`;

export const StyledVideoContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 200px;
  box-shadow: 1px 1px 5px rgba(43, 115, 174, 0.1);

  @media only screen and (min-width: ${breakpoints['xsm']}) {
    max-width: 350px;
  }
  @media only screen and (min-width: ${breakpoints['sm']}) {
    max-width: 600px;
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    max-width: 1000px;
  }
  
`;

export const StyledHeaderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  gap: ${spacing['md']};
  p {
    margin: 0;
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    font-size: 18px;
    max-width: 685px;
    gap: ${spacing['xl']};
  }
`;

export const StyledHeader = styled(Heading)`
  margin: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  color: ${colors['textPrimary']};
  @media only screen and (min-width: ${breakpoints['md']}) {
    font-size: 30px;
  }
`;

export const StyledProcessCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  @media only screen and (min-width: ${breakpoints['md']}) {
    flex-direction: row;
  }
  @media only screen and (min-width: ${breakpoints['lg']}) {
    gap: ${spacing['2xl']};
  }
`;

export const StyledProcessCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${spacing['md']} 0px;
  gap: ${spacing['lg']};
  @media only screen and (min-width: ${breakpoints['md']}) {
    padding: ${spacing['lg']} 0px;
    max-width: 314px;
    gap: ${spacing['2xl']};
  }
  @media only screen and (min-width: ${breakpoints['lg']}) {
    padding: ${spacing['xl']} 0px;
    max-width: 373px;
  }
`;

export const StyledProcessCardImage = styled.img`
  max-height: ${spacing['4xl']};
  @media only screen and (min-width: ${breakpoints['md']}) {
    max-height: 102px;
  }
`;

export const StyledProcessCardContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing['md']};
`;

export const StyledProcessCardHeading = styled.div`
  font-size: 20px;
  font-weight: 800;
  line-height: 150%; /* 30px */
  color: ${colors['textSecondary']};
  @media only screen and (min-width: ${breakpoints['md']}) {
    font-size: 24px;
  }
`;

export const StyledProcessCardDescription = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%; /* 24px */
  color: ${colors['textTertiary']};
`;
