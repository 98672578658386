import React, { useState, useContext } from 'react';

import { I18nContext } from '@scriptscouts/react-platform-client/src/context/I18nContext';

import {
  StyledWrapperContainer,
  StyledContainer,
  StyledHeader,
  StyledContentContainer,
  StyledReviewCardContainer,
  StyledReviewCardImage,
  StyledReviewCardHeading,
  StyledReviewStarsContainer,
  StyledStarIcon,
  StyledReviewNavigationContainer,
  StyledNavigationButton,
  StyledChevronLeftIcon,
  StyledChevronRightIcon,
  StyledProfileContainer,
  StyledStepContainer
} from './style';



const ReviewsComponent = () => {
  const { i18n } = useContext(I18nContext);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const REVIEWS = [
    {
      id      : 'review-rachel-s',
      name    : 'Rachel S.',
      altText : '',
      review  : i18n('rachelSReview'),
      stars   : 5,
      image   : window.CX.STATIC_IMAGE_ASSETS_URL({
        path      : 'reviews/headshot/rachel-s',
        extension : 'svg'
      })
    }
  ];

  const currentReviewDetails = REVIEWS[currentReviewIndex];

  const scrollReview = nextReviewIndex =>{
    if (nextReviewIndex >= REVIEWS.length) {
      nextReviewIndex = 0;
    }
    if (nextReviewIndex < 0) {
      nextReviewIndex = REVIEWS.length - 1;
    }
    setCurrentReviewIndex(nextReviewIndex);
  };

  return (
    <section id="reviews">
      <StyledWrapperContainer>
        <StyledContainer>
          <StyledContentContainer>
            <StyledHeader as="h2">{i18n('reviewContainerHeader')}</StyledHeader>
            <StyledReviewCardContainer key={currentReviewDetails?.id}>
              <StyledReviewCardHeading>&quot;{currentReviewDetails?.review}&quot;</StyledReviewCardHeading>
              <StyledReviewStarsContainer>
                {
                  [...Array(currentReviewDetails?.stars)].map((_, i) => (<StyledStarIcon
                    key={`review-star-${currentReviewDetails?.id}-${i}`}
                    alt={`${currentReviewDetails?.stars} stars review`}
                  />))
                }
              </StyledReviewStarsContainer>
              <StyledReviewNavigationContainer>
                <StyledNavigationButton onClick={() => scrollReview(currentReviewIndex-1)} variant='invisible' aria-label='Previous review'>
                  <StyledChevronLeftIcon />
                </StyledNavigationButton>
                <StyledProfileContainer>
                  <StyledReviewCardImage src={currentReviewDetails?.image} alt={currentReviewDetails?.altText} loading='lazy' />
                  <p>{currentReviewDetails?.name}</p>
                </StyledProfileContainer>
                <StyledNavigationButton onClick={() => scrollReview(currentReviewIndex+1)} variant='invisible' aria-label='Next review'>
                  <StyledChevronRightIcon />
                </StyledNavigationButton>
              </StyledReviewNavigationContainer>
              <StyledStepContainer>
                {
                  REVIEWS.map((review, index) => {
                    if (index === currentReviewIndex) {
                      return (<img
                        src={window.CX.STATIC_IMAGE_ASSETS_URL({
                          path      : 'cx/active-step',
                          extension : 'svg',
                          options   : ['width=24']
                        })}
                        alt=''
                        key={`active-step-icon-${review}`}
                        onClick={() => scrollReview(index)}
                        loading='lazy'
                      />);
                    }
                    return (<img
                      src={window.CX.STATIC_IMAGE_ASSETS_URL({
                        path      : 'cx/inactive-step',
                        extension : 'svg',
                        options   : ['width=24']
                      })}
                      alt=''
                      key={`inactive-step-icon-${review}-${index}`}
                      onClick={() => scrollReview(index)}
                      loading='lazy'
                    />);
                  })
                }
              </StyledStepContainer>
            </StyledReviewCardContainer>
          </StyledContentContainer>
        </StyledContainer>
      </StyledWrapperContainer>
    </section>
  );
};

export default ReviewsComponent;
