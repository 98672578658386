import React, { lazy, Suspense, useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import EnsureAuthorized from '@scriptscouts/react-platform-client/src/components/authGate/EnsureAuthorized';

import AppProviders from './AppProviders';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Navigation, Footer } from '@scriptscouts/react-platform-client/src/components/common';
import { I18nContext } from '@scriptscouts/react-platform-client/src/context/I18nContext';
import Landing from './Pages/Landing';

import { Primer } from '@scriptscouts/react-platform-client/src/components/common';
const { PageLayout, Spinner } = Primer;

const Gated = lazy(() => import('./Pages/Gated'));
const MedicationPrices = lazy(() => import('./Pages/MedicationPrices'));
const ThankYou = lazy(() => import('./Pages/ThankYou'));
const Checkout = lazy(() => import('./Pages/Checkout'));
const CheckoutReturn = lazy(() => import('./Pages/CheckoutReturn'));
const FAQ = lazy(() => import('./Pages/FAQ'));
const HowItWorks = lazy(() => import('./Pages/HowItWorks'));
const MembershipBenefits = lazy(() => import('./Pages/MembershipBenefits'));
const SignMedicalReleaseForm = lazy(() => import('./Pages/SignMedicalReleaseForm'));
const ExternalRedirect = lazy(() => import('@scriptscouts/react-platform-client/src/components/common/ExternalRedirect'));

const App = () => {
  const { i18n } = useContext(I18nContext);

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}> 
      <AppProviders>
        <Suspense fallback={<Spinner size='large' className='suspense-spinner' />}>
          <Router basename="/rx">
            <PageLayout padding="none" columnGap="none" rowGap="none" containerWidth="full">
              <PageLayout.Header>
                <Navigation />
              </PageLayout.Header>
              <PageLayout.Content id="page-content">
                <Routes>
                  <Route path="/" element={<Landing />} />
                  <Route path="/redirect" element={<ExternalRedirect i18n={i18n} />} />
                  <Route path="/medication-prices" element={<MedicationPrices />} />
                  <Route path="/gated" element={<EnsureAuthorized element={Gated} />} />
                  <Route path="/thank-you" element={<ThankYou/>}  />
                  <Route path="/checkout" element={<EnsureAuthorized element={Checkout} />}  />
                  <Route path="/checkout/return" element={<EnsureAuthorized element={CheckoutReturn} />}  />
                  <Route path="/faq" element={<FAQ />}  />
                  <Route path="/how-it-works" element={<HowItWorks />}  />
                  <Route path="/membership-benefits" element={<MembershipBenefits />}  />
                  <Route path="/medical-release-form" element={<EnsureAuthorized element={SignMedicalReleaseForm} />}  />
                </Routes>
              </PageLayout.Content>
              <PageLayout.Footer>
                <Footer />
              </PageLayout.Footer>
            </PageLayout>
          </Router>
        </Suspense>
      
      </AppProviders>
    </ErrorBoundary>
  );
};

export default App;
