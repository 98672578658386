import styled                             from 'styled-components';
import { Primer, PrimerOcticons }         from '@scriptscouts/react-platform-client/src/components/common';
import { colors }                         from '@scriptscouts/react-platform-client/src/utility/colors';
import {
  spacing,
  breakpoints,
  radius
} from '@scriptscouts/react-platform-client/src/utility/measurements';

const { CheckCircleFillIcon, ClockIcon } = PrimerOcticons;
const { Heading } = Primer;

export const StyledContainer = styled.div`
  color: ${colors['textPrimary']};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing['xs']};
  align-self: stretch;
  margin-bottom: ${spacing['4xl']};
  width: 100%;
  text-align: center;
  font-family: Manrope;
`;

export const StyledHeader = styled(Heading)`
  color: ${colors['textPrimary']};
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  margin: 0;
  @media only screen and (min-width: ${breakpoints['md']}) {
    font-size: 30px;
  }
`;

export const StyledBenefitsCardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: auto;

  align-items: center;
  gap: 30px;
  flex-shrink: 0;
  padding: 30px 0;
  width: 100%;

  // Hide scrollbar
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  :-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: ${breakpoints['md']}) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const StyledBenefitsCard = styled.div`
  display: flex;
  padding: ${spacing['2xl']};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${spacing['xl']};
  border-radius: ${radius['lg']};
  background: ${colors['surfaceBase']};
  min-width: 240px;
  line-height: 150%;
  box-shadow: 0px 78px 22px 0px rgba(43, 115, 174, 0.00),
    0px 50px 20px 0px rgba(43, 115, 174, 0.01),
    0px 28px 17px 0px rgba(43, 115, 174, 0.02),
    0px 12px 12px 0px rgba(43, 115, 174, 0.03),
    0px 3px 7px 0px rgba(43, 115, 174, 0.04);
`;

export const StyledBenefitContainer = styled.div`
  display: flex;
  gap: ${spacing['md']};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const StyledBenefitHeader = styled.div`
  color: ${colors['textInvertedSecondary']};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`;

export const StyledBenefitFeatures = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  p {
    display: flex;
    align-items: flex-start;
    gap: var(--Spacing-md, 8px);
    margin: 0;
  }
  .strikethrough {
    text-decoration: line-through;
    color: ${colors['textMuted']};
  }
  .strikethrough.primary {
   color: ${colors['textInvertedPrimary']};
  }

`;

export const StyledCheckboxIcon = styled(CheckCircleFillIcon)`
  margin-right: ${spacing['sm']};
  padding-top: 5px;
  color: ${colors['surfacePositive']};
`;

export const StyledClockIcon = styled(ClockIcon)`
  margin-right: ${spacing['sm']};
  padding-top: 5px;
  color: ${colors['textLightMuted']};
`;
