import React     from 'react';
import PropTypes from 'prop-types';

import { Primer }               from '@scriptscouts/react-platform-client/src/components/common';
import { TransferConsumer }     from '@scriptscouts/react-platform-client/src/components/appTransfers';
import { AuthProvider }         from '@scriptscouts/react-platform-client/src/context/AuthContext';
import { NotificationProvider } from '@scriptscouts/react-platform-client/src/context/NotificationContext';
import { SeoProvider }          from '@scriptscouts/react-platform-client/src/context/SeoContext';
import { I18nProvider }         from '@scriptscouts/react-platform-client/src/context/I18nContext';
import { DialogProvider }       from '@scriptscouts/react-platform-client/src/context/DialogContext';
import { MedicationProvider }   from './Context/MedicationContext';

import appSpecificTranslations from './utils/translationStrings';

const { ThemeProvider } = Primer;

const AppProviders = ({ children }) => {
  return (
    <I18nProvider translations={appSpecificTranslations}>
      <TransferConsumer>
        <SeoProvider>
          <NotificationProvider>
            <AuthProvider>
              <DialogProvider>
                <ThemeProvider>
                  <MedicationProvider>
                    {children}
                  </MedicationProvider>
                </ThemeProvider>
              </DialogProvider>
            </AuthProvider>
          </NotificationProvider>
        </SeoProvider>
      </TransferConsumer>
    </I18nProvider>
  );
};

AppProviders.propTypes = {
  children : PropTypes.node
};

export default AppProviders;
