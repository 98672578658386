import React, { useContext } from 'react';
import { I18nContext } from '@scriptscouts/react-platform-client/src/context/I18nContext';

import { spacing } from '@scriptscouts/react-platform-client/src/utility/measurements';

import {
  StyledContainer,
  StyledHeader,
  StyledProvidersList
} from './style';


const ProvidersDisplay = () => {
  const { i18n } = useContext(I18nContext);
  return (
    <StyledContainer id="providers">
      <StyledHeader as="h2">{i18n('providersDisplayHeader')}</StyledHeader>
      <StyledProvidersList>
        <img
          src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
            path      : 'logos/costplus',
            extension : 'svg',
            options   : [`height=${spacing['xl']}`]
          })}`}
          alt=''
          loading='lazy'
        />
        <img
          src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
            path      : 'logos/singlecare',
            extension : 'svg',
            options   : [`height=${spacing['xl']}`]
          })}`}
          alt=''
          loading='lazy'
        />
        <img
          src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
            path      : 'logos/wellrx',
            extension : 'svg',
            options   : [`height=${spacing['xl']}`]
          })}`}
          alt=''
          loading='lazy'
        />
        <img
          src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
            path      : 'logos/blinkhealth',
            extension : 'svg',
            options   : [`height=${spacing['xl']}`]
          })}`}
          alt=''
          loading='lazy'
        />
      </StyledProvidersList>
    </StyledContainer>
  );
};

export default ProvidersDisplay;
