import styled from 'styled-components';
import { Primer, PrimerOcticons } from '@scriptscouts/react-platform-client/src/components/common';

import { Button } from '@scriptscouts/react-platform-client/src/components/common';
import { colors } from '@scriptscouts/react-platform-client/src/utility/colors';
import {
  spacing,
  radius,
  breakpoints
} from '@scriptscouts/react-platform-client/src/utility/measurements';

const { StarFillIcon, ChevronLeftIcon, ChevronRightIcon } = PrimerOcticons;
const { Heading } = Primer;

export const StyledWrapperContainer  = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 ${spacing['md']};
  margin-bottom: ${spacing['4xl']};
  overflow-y: auto;
  @media only screen and (min-width: ${breakpoints['sm']}) {
    padding: 0 ${spacing['xl']};
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    gap: ${spacing['xl']};
  }
`;

export const StyledContainer = styled.div`
  color: ${colors['textPrimary']};
  text-align: center;
  font-family: Manrope;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  min-height: 400px;
  border-radius: ${radius['lg']};
  border: 1px solid ${colors['borderBrandLigher']};
  background: ${colors['surfaceBase']};
`;

export const StyledHeader = styled(Heading)`
  margin: 0;
  font-family: Manrope;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  padding: ${spacing['xl']};
  flex-direction: column;
  align-items: center;
  gap: ${spacing['3xl']};
  @media only screen and (min-width: ${breakpoints['md']}) {
    gap: ${spacing['2xl']};
  }
`;

export const StyledReviewCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing['xl']};
  width: 100%;
`;

export const StyledReviewCardImage = styled.img`
  max-height: ${spacing['4xl']};
`;

export const StyledReviewCardHeading = styled.div`
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: ${colors['textSecondary']};
  @media only screen and (min-width: ${breakpoints['sm']}) {
    font-size: 20px;
    font-weight: 800;
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    font-size: 24px;
  }
`;

export const StyledReviewStarsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

export const StyledStarIcon = styled(StarFillIcon)`
  color: ${colors['surfaceAccent']}
`;

export const StyledReviewNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
`;

export const StyledNavigationButton = styled(Button)`
  background: transparent;
  :hover, :focus {
    border: 0;
  }
`;

export const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  padding: 0;
  margin: 0;
`;

export const StyledChevronRightIcon = styled(ChevronRightIcon)`
  padding: 0;
  margin: 0;
`;

export const StyledProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    color: ${colors['textTertiary']};
  }
`;

export const StyledStepContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing['lg']};

  img {
    cursor: pointer;
  }
`;
