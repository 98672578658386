import React, { useContext } from 'react';
import ReactPlayer from 'react-player/lazy';
import { I18nContext } from '@scriptscouts/react-platform-client/src/context/I18nContext';

import {
  StyledContainer,
  StyledDottedSeparatorImage,
  StyledVideoContainer,
  StyledHeader,
  StyledHeaderContentContainer,
  StyledProcessCardsContainer,
  StyledProcessCardContainer,
  StyledProcessCardImage,
  StyledProcessCardContentsContainer,
  StyledProcessCardHeading,
  StyledProcessCardDescription
} from './style';

const ProcessComponent = () => {
  const { i18n } = useContext(I18nContext);

  const PROCESS_CARDS = [
    {
      id          : 'search_medications',
      altText     : '',
      heading     : i18n('searchMedicationsTitle'),
      description : i18n('searchMedicationsDescription'),
      image       : `${window.CX.STATIC_IMAGE_ASSETS_URL({
        path      : 'cx/medications',
        extension : 'svg'
      })}`
    },
    {
      id          : 'compare_prices',
      altText     : '',
      heading     : i18n('comparePricesTitle'),
      description : i18n('comparePricesDescription'),
      image       : `${window.CX.STATIC_IMAGE_ASSETS_URL({
        path      : 'cx/prices',
        extension : 'svg'
      })}`
    },
    {
      id          : 'retrieve_discounts',
      altText     : '',
      heading     : i18n('retrieveDiscountsTitle'),
      description : i18n('retrieveDiscountsDescription'),
      image       : `${window.CX.STATIC_IMAGE_ASSETS_URL({
        path      : 'cx/discounts',
        extension : 'svg'
      })}`
    }
  ];

  return (
    <section id="our-process">
      <StyledContainer>
        <StyledDottedSeparatorImage
          src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
            path      : 'cx/dotted-separator-vertical',
            extension : 'svg'
          })}`}
          alt=''
          loading='lazy'
        />
        <ReactPlayer
          url='https://www.youtube.com/watch?v=V-B1VXFnri0&rco=1'
          controls={true}
          wrapper={StyledVideoContainer}
          id="intro-video"
        />
        <StyledHeaderContentContainer>
          <StyledHeader as="h2">{i18n('processContainerHeader')}</StyledHeader>
          <p>{i18n('processContainerDescription')}</p>
        </StyledHeaderContentContainer>
        <StyledProcessCardsContainer>
          {
            PROCESS_CARDS.map(card => (
              <StyledProcessCardContainer key={card.id}>
                <StyledProcessCardImage src={card.image} alt={card.altText} loading='lazy' />
                <StyledProcessCardContentsContainer>
                  <StyledProcessCardHeading>{card.heading}</StyledProcessCardHeading>
                  <StyledProcessCardDescription>{card.description}</StyledProcessCardDescription>
                </StyledProcessCardContentsContainer>
              </StyledProcessCardContainer>
            ))
          }
        </StyledProcessCardsContainer>
      </StyledContainer>
    </section>
  );
};

export default ProcessComponent;
