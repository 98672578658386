/* eslint-disable max-len */

export default {
  aphoraRx                       : 'AphoraRx',
  aphoraRxProcess                : 'AphoraRx process',
  howItWorks                     : 'How it works',
  membershipBenefits             : 'Membership benefits',
  becomeAMember                  : 'Become a member',
  ourCompany                     : 'Our company',
  login                          : 'Log in',
  logout                         : 'Logout',
  aboutAphoraRx                  : 'About AphoraRx',
  contactUs                      : 'Contact Us',
  frequentlyAskedQuestions       : 'Frequently Asked Questions',
  privacy                        : 'Privacy',
  privacyDisclaimer              : 'We don’t share your personal information or medication list. Ever. With anyone. Rest assured that your information is kept confidential and cared for with high security standards.',
  noteToOurCustomers             : 'Note to our customers',
  noteToOurCustomersDescription  : 'We’ve built AphoraRx to work in the United States. Prices listed are in USD. If you have a need for prescription drug savings in a different country, feel free to reach out to our team.',
  privacyPolicy                  : 'Privacy Policy',
  termsOfUse                     : 'Terms of Use',
  joinPremium                    : 'Join AphoraRx Premium',
  joinPremiumDescription         : 'Want to save the maximum amount on your prescriptions? Join AphoraRx Premium Today and Unlock Exclusive Benefits.',
  processContainerHeader         : 'The AphoraRx process',
  processContainerDescription    : 'We’ve perfected our process, ensuring you effortlessly access the savings you deserve for a healthier and more affordable life.',
  signupForPremiumToday          : 'Sign up for premium today',
  searchMedicationsTitle         : 'Search your medications',
  searchMedicationsDescription   : 'Effortlessly search multiple medications at one time and discover the best prescription discounts on the internet. ',
  comparePricesTitle             : 'Compare prices',
  comparePricesDescription       : 'Compare prescription discounts from multiple sources to find the best price that suits you, either from multiple locations or just one!',
  retrieveDiscountsTitle         : 'Retrieve your discounts',
  retrieveDiscountsDescription   : 'Easily text, email, share, or print your coupons so that you have them easily accessible when it’s time to pick up your prescriptions.',
  searchDiscountsHeader          : 'Search prescription drug discounts',
  searchDiscountsDescription     : 'Find the best overall price on your prescription drugs.',
  searchDiscountsPlaceholder     : 'Search prescription drugs',
  upgradeToPremiumTitle          : 'Upgrade to Premium for Additional Benefits',
  upgradeToPremiumDescription    : 'Save and track all your prescriptions in one place. Let us transfer your prescriptions for you so you can save both time and money.  Set your preferred pharmacy so that you can pick up all your prescriptions in one place. ',
  zipCodePlaceholder             : 'Zip code',
  addPrescription                : 'Add prescription',
  providersDisplayHeader         : 'Bringing you the best prices from:',
  reviewContainerHeader          : 'What customers are saying about AphoraRx',
  rachelSReview                  : 'We saved over $50 per pickup on my son’s Adderall prescription. I didn’t realize the price variations between pharmacies were so high!',
  aphoraRxBenefits               : 'AphoraRx benefits',
  otherCompanies                 : 'Other Companies',
  free                           : 'Free',
  premium                        : 'Premium',
  signupForPremium               : 'Sign up for Premium',
  signupForFree                  : 'Sign up for free',
  monthShorthand                 : 'mo',
  findDiscounts                  : 'Find prescription discounts',
  searchMultipleProviders        : 'Search multiple providers',
  searchMultiplePrescriptions    : 'Search multiple prescriptions at a time',
  findOverAllPriceMultipleDrugs  : 'Find best overall price for multiple drugs',
  savePreferredPharmacy          : 'Save preferred pharmacy',
  trackPricesForMultipleDrugs    : 'Track best price for multiple drugs',
  transferPrescriptions          : 'Transfer prescriptions to different pharmacies',
  prescriptionSearch             : 'Prescription search',
  noSearchResults                : 'No prescription medications found',
  loadingPrices                  : 'We’re retrieving the best prices from over 300 pharmacies.',
  prescriptionDetails            : 'Prescription details',
  prescriptionUpdateInfo         : 'Updating your prescription may change which pharmacies honor the best prices.',
  editPrescription               : 'Edit prescription',
  of                             : 'of',
  prescriptionResultsHeader      : 'Your prescription discount results',
  prescriptionResultsSubHeader   : 'Here are the best prices based on the prescriptions you searched. AphoraRx searched both online and in-person pharmacies.',
  totalWithAphoraRx              : 'AphoraRx Powered Discount Search Results',
  pharmaciesSearched             : 'Over 300 pharmacies searched.',
  totalWithoutAphoraRx           : 'What Big Pharma Wants You To Pay',
  saveMoreUsingOneService        : 'If you choose to pay full price',
  retailPrice                    : 'Retail price we are seeing:',
  aphoraRxPrice                  : 'Lowest price we found:',
  findPrices                     : 'Find prices',
  signupToSave                   : 'Signup to save',
  additionalPriceOptions         : 'Additional pharmacy price options',
  retail                         : 'Retail',
  retailPercentageLabel          : 'Save %s when you sign up',
  selectForm                     : 'Select form',
  selectDosage                   : 'Select dosage',
  selectQuantity                 : 'Select quantity',
  form                           : 'Form',
  dosage                         : 'Dosage',
  quantity                       : 'Quantity',
  updatePrescription             : 'Update prescription',
  cancel                         : 'Cancel',
  selectPharmacyOption           : 'Select a preferred pharmacy to get coupon details.',
  textCoupon                     : 'Text coupon',
  emailCoupon                    : 'E-mail coupon',
  providedBy                     : 'Provided by',
  cheapestPrice                  : 'Cheapest price',
  bin                            : 'BIN',
  pcn                            : 'PCN',
  group                          : 'Group',
  memberId                       : 'Member ID',
  prescriptionSavingsHeader      : 'See all your prescriptions in one place and set your preferred pharmacy.',
  prescriptionSavingsButton      : 'My prescription savings',
  loading                        : 'Loading...',
  thisIsNotInsurance             : 'This is not insurance',
  pricingErrorMessage            : 'Something went wrong while fetching prices for %s. Please try again later.',
  medicationAdjustment           : 'Remove from my Prescription savings',
  searchRadius                   : 'Search radius',
  mile                           : 'mile',
  miles                          : 'miles',
  enterValidZipCode              : 'Enter a valid zip code',
  enterAtLeastOneMedication      : 'Enter at least one medication',
  medicationError                : 'cannot be loaded',
  medicationUnavailable          : 'Sorry, we weren’t able to find your medication. Try searching again or reach out to ',
  contactSupportMessage          : 'to request that we add a specific medication or pharmacy to our database. Thanks!',
  supportLink                    : 'support@aphorahealth.com ',
  viewOnlinePharmacyCoupon       : 'Visit online pharmacy',
  selectedPharmacy               : 'Selected pharmacy',
  medication                     : 'Medication',
  externalPharmacyDisclaimer     : 'You will be redirected to an external pharmacy website and may need to re-enter some information.',
  externalRedirectHeader         : 'You are being redirected to an external website.',
  externalRedirectMessage        : 'You will be redirected to an external website in %s seconds. Please click on the link below if you are not automatically redirected.',
  contactUsMessage               : 'We\'d love to hear from you! Whether you have questions, feedback, or want to partner with us, we\'re here to help.',
  firstName                      : 'First Name',
  enterFirstName                 : 'Enter first name',
  firstNameIsRequired            : 'First Name is required',
  firstNameValidationError       : 'First Name is invalid',
  lastName                       : 'Last Name',
  enterLastName                  : 'Enter last name',
  lastNameValidationError        : 'Last Name is invalid',
  lastNameIsRequired             : 'Last Name is required',
  email                          : 'E-mail',
  enterEmail                     : 'Enter email',
  emailValidationError           : 'Email is invalid',
  emailIsRequired                : 'Email Name is required',
  reasonContact                  : 'Reason for contact',
  selectOne                      : 'Select one',
  reasonContactValidationError   : 'Reason for contact is invalid',
  reasonContactIsRequired        : 'Reason for contact is required',
  subject                        : 'Subject',
  enterSubject                   : 'Enter subject',
  subjectIsRequired              : 'Subject is required',
  subjectValidationError         : 'Subject Name is invalid',
  message                        : 'Message',
  enterMessage                   : 'Enter message...',
  messageIsRequired              : 'Message is required',
  messageValidationError         : 'Message is invalid',
  faqs                           : 'Frequently asked questions',
  whatsMission                   : 'What is AphoraRx\' mission?',
  whatsMissionDescription        : 'We\'re on a mission to help people get their prescription drugs that are often life-saving at the lowest price.',
  howDoesItWork                  : 'How does AphoraRx work?',
  howDoesItWorkDescription       : 'Anyone can sign up online, enter in the prescriptionI(s) you need, and get updates on prices, discount codes, at their local or online pharmacy.',
  isMyInfoShared                 : 'Does AphoraRx share my information with 3rd party platforms?',
  isMyInfoSharedDescription      : 'We don\'t share your personal information or medication list. Ever. With anyone. Rest assured that your information is kept confidential and cared for with high security standards.',
  isItInsurance                  : 'Is AphoraRx health insurance?',
  isItInsuranceDescription       : 'No. AphoraRx is simply a software based search engine. AphoraRx searches multiple sources and reputable manufacturer\'s coupons to reduce your manual efforts.',
  searchInsurance                : 'Does AphoraRx search health insurance prices?',
  searchInsuranceDescription     : 'The sources it searches are not based on health insurance either - the discounts and prices the search engine finds is based on “cash pay” or out-of-pocket prices for medications.',
  diffWithProvider               : 'What is the difference between AphoraRx and other Rx coupon code websites?',
  diffWithProviderDescription    : 'AphoraRx is a search engine of the prescription drug market. It searches sources from across the web to determine the best price and pickup location for your medications. Sources include prescription drug coupon providers and online pharmacies, like SingleCare and CostPlusDrugs.',
  subscriptions                  : 'Subscriptions',
  needInsurance                  : 'Do I need health insurance to have a subscription?',
  needInsuranceDescription       : 'No, we search “cash pay” prices which will help you get the lowest prices regardless of your insurance coverage.',
  howToCancel                    : 'How do I cancel my subscription?',
  howToCancelDescriptionSection1 : 'If you need to cancel your subscription, please contact us at ',
  howToCancelDescriptionSection2 : ' and we will assist you. Even after canceling, you can continue using the subscription features until the end of your subscription period. After your license expires, you will still have access to AphoraRx.com with the features available in the free tier. Please note that free tier features may change over time. If you need to permanently delete your account, simply mention that in your email and we’ll work with you to ensure all your information is safely and securely deleted from our systems.',
  general                        : 'General',
  whatIsCashPay                  : 'What is cash pay?',
  whatIsCashPayDescription       : 'Cash pay is when a prescription holder literally “pays cash” for a medication instead of going through insurance. Often times, cash pay prices beat out insurance prices.',
  howOftenPriceChange            : 'How often are prescription drug prices changing?',
  howOftenPriceChangeDescription : 'In recent research, prices can vary daily.',
  whatIsNADAC                    : 'What is NADAC?',
  whatIsNADACDescription         : 'NADAC stands for “National Average Drug Acquisition Cost.” It is a pricing benchmark created and maintained by the Centers for Medicare & Medicaid Services (CMS). NADAC represents the average price at which retail community pharmacies and long-term care (LTC) pharmacies purchase prescription drugs from wholesalers. This benchmark is updated weekly, providing an up-to-date reflection of drug prices.',
  whatIsPriorAuth                : 'What is Prior Authorization?',
  whatIsPriorAuthDescription     : 'Prior authorization is a cost-control process used by health insurance companies where they require healthcare providers to obtain approval before performing a service, prescribing a medication, or providing a treatment to ensure it is covered under a patient’s health insurance plan. This means that before certain medications can be dispensed or treatments provided, the insurance company must first review and agree that the procedure or medication is medically necessary.',
  theAphoraRxProcess             : 'The AphoraRx process',
  comparePrescPricesTitle        : 'Compare prescription prices',
  asOfDate                       : '(as of %s)',
  thankYou                       : 'Thank you for becoming a AphoraRx member!',
  thankYouCountdown              : 'This window will close automatically in',
  seconds                        : 'seconds.',
  yourFreeBenefits               : 'Your free benefits',
  premiumBenefitsComingSoon      : 'Premium Benefits Coming Soon...',
  foundersHeader                 : 'Founders',
  foundersHeaderDescription      : 'Our passionate and dedicated team brings together a diverse range of skills and expertise to deliver exceptional results. From creative visionaries to meticulous strategists, we work seamlessly together to meet and exceed our customers’ expectations.',
  founderNameCeo                 : 'Andy Chan',
  founderNameCto                 : 'Sean Cannon',
  founderNameCmo                 : 'Dr. Leo Spector',
  founderTitleCeo                : 'Cheif Executive Officer',
  founderTitleCto                : 'Cheif Technology Officer',
  founderTitleCmo                : 'Cheif Medical Officer',
  founderEmailCeo                : 'andy@aphorahealth.com',
  founderEmailCto                : 'sean@aphorahealth.com',
  founderEmailCmo                : 'leo@aphorahealth.com',
  founderDescriptionCeo          : 'Andy is an experienced product leader with over 10 years of experience in product development. He has been involved in a wide range of startups in FinTech and Machine Learning; he has one IPO under his belt and holds three patents.',
  founderDescriptionCto          : 'Sean has over 25 years of experience developing enterprise software applications across startups and Fortune 500 companies. His expertise in agile software development helps drive ScriptScout’s engineering and artificial intelligence initiatives.',
  founderDescriptionCmo          : 'Dr. Spector is a practicing Spine Surgeon with over 15 years of experience at one of the country’s largest privately owned practices. He was also an Associate Professor of Orthopedic Surgery for 5 years.',
  patientName                    : 'Patient Name',
  patientDOB                     : 'Patient Date of Birth',
  patientSignature               : 'Patient Signature',
  submit                         : 'Submit',
  hipaaAuthorization             : 'HIPAA Authorization for Disclosure of Protected Health Information to TrackRecord Health',
  hipaaAuthorizationPara1        : 'I authorize and request any health plan, physician, health care professional, hospital, clinic, laboratory, pharmacy, medical facility, or other health care provider that has provided payment, treatment, or services to me or on my behalf to disclose the following Protected HealthInformation (“PHI”) about me:',
  hipaaAuthorizationPara2        : 'Complete Medical Record(s) from the initiation of care to present date',
  hipaaAuthorizationSendTo       : 'The above-described information should be sent to',
  byEmailTo                      : 'by email to',
  orByFaxTo                      : 'or by fax to',
  hipaaAuthorizationPara3        : 'I also understand and agree to the following:',
  iWillReceiveCopy               : 'I will receive a copy of this signed authorization by email.',
  iUnderstandAndAgree            : 'I understand that my treatment or services to me will not be conditioned on whether I sign this authorization.',
  iUnderstandAndAgree2           : 'I understand that because TrackRecord Health is not a health plan or health care provider that any information used or disclosed because I have signed this authorization may no longer be protected by federal privacy laws and may be subject to re-disclosure by the person or organization receiving it.',
  iHaveTheRightToRevoke          : 'I have the right to revoke this authorization at any time by emailing Aphora Health at support@aphorahealth.com. Any revocation of this authorization by me will not apply to actions that TrackRecord Health has already taken regarding the use of my PHI during the period of time that my authorization was effective.',
  thisAuthExpiresTwoYears        : 'This authorization expires two years from the date it is signed.',
  acceptAndUnderstandTerms       : 'By signing below, I represent that I am 18 years old or older and that I understand and authorize the disclosure of my information as described in this form.',
  submitButton                   : 'Submit',
  nextButton                     : 'Next',
  clearButton                    : 'Clear',
  downloadForm                   : 'Download Signed Form'
};
/* eslint-enable max-len */
