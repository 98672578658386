import translationStrings from './translationStrings';

let translations = {};

const setTranslations = data => {
  translations = data;
};

// TODO - Handle translations properly via a provider. This is temporary.
setTranslations(translationStrings);

const i18n = key => translations[key] || key;

export { i18n, setTranslations };
