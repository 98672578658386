import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { getAllPublicProviderTenantDetails } from '@scriptscouts/react-platform-client/src/utility/services/Provider';

import { SEARCH_PROXIMITY_RADIUS_DEFAULT_SELECTED } from '../utils/utils';

import {
  getStoredMedicationContext,
  maybePassivelyPersistToStorage
} from '@scriptscouts/react-platform-client/src/utility/storage';

const MedicationContext = createContext();

const MedicationProvider = ({ children }) => {
  const [selectedMedicationsSelectedOffer, setSelectedMedicationsSelectedOffer] = useState({});
  const [medicationPrices, setMedicationPrices] = useState([]);
  const [zipCode, setZipCode] = useState('');
  const [searchRadiusInMiles, setSearchRadiusInMiles] = useState(SEARCH_PROXIMITY_RADIUS_DEFAULT_SELECTED.id);
  const [providers, setProviders] = useState([]);
  const [pharmacies] = useState([]);

  useEffect(() => {
    const storedMedicationData = getStoredMedicationContext();
    storedMedicationData?.selectedMedicationsSelectedOffer ? setSelectedMedicationsSelectedOffer(storedMedicationData.selectedMedicationsSelectedOffer) : null;
    storedMedicationData?.zipCode ? setZipCode(storedMedicationData.zipCode) : null;
    storedMedicationData?.medicationPrices ? setMedicationPrices(storedMedicationData.medicationPrices) : null;
    storedMedicationData?.searchRadiusInMiles ? setSearchRadiusInMiles(storedMedicationData.searchRadiusInMiles) : null;

    const hasCachedProviders = storedMedicationData?.providers && !R.isEmpty(storedMedicationData.providers);
    if (hasCachedProviders) {
      setProviders(storedMedicationData.providers);
    } else {
      getAllPublicProviderTenantDetails()
        .then(setProviders)
        .catch(() => setProviders([]));
    }
  }, []);

  useEffect(() => {
    maybePassivelyPersistToStorage({
      medicationContext : {
        zipCode,
        medicationPrices,
        selectedMedicationsSelectedOffer,
        providers,
        pharmacies,
        searchRadiusInMiles
      }
    });
  }, [zipCode, searchRadiusInMiles, medicationPrices, selectedMedicationsSelectedOffer, providers, pharmacies]);

  return (
    <MedicationContext.Provider value={{
      selectedMedicationsSelectedOffer,
      setSelectedMedicationsSelectedOffer,
      zipCode,
      setZipCode,
      searchRadiusInMiles,
      setSearchRadiusInMiles,
      medicationPrices,
      setMedicationPrices,
      providers,
      pharmacies
    }}>
      {children}
    </MedicationContext.Provider>
  );
};

export { MedicationProvider, MedicationContext };

MedicationProvider.propTypes = {
  children : PropTypes.node
};
