import React from 'react';
import { Primer }               from '@scriptscouts/react-platform-client/src/components/common';
import SearchDiscountsComponent from '../../Components/SearchDiscounts';
import ProcessComponent         from '../../Components/Process';
import ReviewContainer          from '../../Components/Reviews';
import BenefitsContainer        from '../../Components/Benefits';

const { Box } = Primer;

const Landing = () => {
  return (
    <Box>
      <SearchDiscountsComponent />
      <ProcessComponent />
      <BenefitsContainer />
      <ReviewContainer />
    </Box>
  );
};

export default Landing;
